<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { pageResize } from './utils/devicePixelRatio'
export default {
    name: 'App',
    data() {
        return {}
    },
    created() {
        if (sessionStorage.getItem('store')) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
        }
        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem('store', JSON.stringify(this.$store.state));
        });
    },
    mounted() {
        pageResize();
    }
}
</script>

<style lang="scss">
@import "./assets/css/common";
@import "./assets/css/main";

#app {
    //overflow-y: hidden;
}
</style>
