import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import {Message} from "element-ui";
import qs from 'qs'
import MyLoading from './plugs/loading'
import Print from 'vue-print-nb'
import * as echarts from 'echarts'
import ElementUI from 'element-ui';
import './assets/css/element-variables.scss';
import { VueMasonryPlugin } from 'vue-masonry';


// 请求处理
// axios.defaults.baseURL = 'http://erp.d33221.com:6300/'
axios.defaults.baseURL = 'https://1927.mzth.cn/'
axios.interceptors.request.use(config => {
    let token = ((store.state || {}).user || {}).token;
    if (token) {
        config.headers.token = token;
    }
    return config;
}, error => {
    return error;
});
axios.interceptors.response.use(
    response => {
        switch (parseInt(response.data.code)) {
            case 200:
                // 成功
                if (response.data.msg && response.data.msg !== 'success') {
                    // Message.success(response.data.msg)
                }
                return response;
            case 300:
                // 成功
                Message.error(response.data.msg);
                return response;
            case 401:
                Message.error('请登录后操作');
                // 需要登录
                store.commit('setToken', '');
                store.commit('setUserInfo', {data: '', status: false});
                router.push('/Login')
                return response;
            default:
                // 失败
                // Message.error(response.data.msg);
                return response
        }
    },
    error => {
        if (error.response) {
			if (error.response.status == 404) {
                Message.error('正在请求不存在的服务器记录');
			} else if (error.response.status == 300) {
                Message.error(error.response.data.msg);
			}  else if (error.response.status == 500) {
                Message.error('服务器发生错误！');
			} else if (error.response.status == 401) {
                Message.error('请登录后操作');
                // 需要登录
                store.commit('setToken', '');
                store.commit('setUserInfo', {data: '', status: false});
                router.push('/Login')
			} else {
                Message.error('服务器发生错误！');
			}
		} else {
			Message.error('服务器发生错误！');
		}

		return Promise.reject(error.response);
    }
);

// 路由处理
router.beforeEach((to, from, next) => {
    // 路由发生变化修改页面title
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.name == 'Login' || to.name == 'Index'|| to.name == 'noticeDetails'|| to.name == 'policiesDetails' || to.name == 'shopDetails') {
        next();
    } else {
        if (store.state.user.loginStatus){
            if(!to.name) {
                next('/Login')
            } else {
                next()
            }
        }else {
            next('/Login')
        }

    }
});

Vue.use(MyLoading)
Vue.use(Print);
Vue.prototype.$qs = qs;
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueMasonryPlugin);
Vue.prototype.$bus = new Vue()

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
// window.addEventListener('popstate',function () {
//     history.pushState(null,null,document.URL)
// })
