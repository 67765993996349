import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


// 解决vue-router在3.0版本以上重复点击的报错问题
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};
const routes = [
    {
        path: '/Login',
        name: 'Login',
        component: () => import( '../views/Login.vue'),
        meta: {
            title: '登录-萤视界科技'
        }
    },
    {
        path: '/Layout',
        name: 'Layout',
        component: () => import( '../views/Layout.vue'),
        meta: {},
        children: [
            {
                path: '/noticeDetails',
                name: 'noticeDetails',
                component: () => import( '../views/noticeDetails.vue'),
                meta: {
                    title: '公告详情'
                }
            },
            {
                path: '/policiesDetails',
                name: 'policiesDetails',
                component: () => import( '../views/policiesDetails.vue'),
                meta: {
                    title: '优惠详情'
                }
            },
            {
                path: '/shop',
                name: 'shop',
                component: () => import( '../views/shop.vue'),
                meta: {
                    title: '购物车'
                }
            },
            {
                path: '/ConfirmOrder',
                name: 'ConfirmOrder',
                component: () => import( '../views/ConfirmOrder.vue'),
                meta: {
                    title: '确认下单'
                }
            },
            {
                path: '/Index',
                name: 'Index',
                component: () => import( '../views/Index.vue'),
                meta: {
                    title: '首页-萤视界科技'
                }
            },
            {
                path: '/shopDetails',
                name: 'shopDetails',
                component: () => import( '../views/shopDetails.vue'),
                meta: {
                    title: '商品详情'
                }
            },
            {
                path: '/customize',
                name: 'customize',
                component: () => import( '../views/customize.vue'),
                meta: {
                    title: '装机-萤视界科技'
                }
            },
            {
                path: '/pay',
                name: 'pay',
                component: () => import( '../views/pay.vue'),
                meta: {
                    title: '支付'
                }
            },
        ]
    },
    {
        path: '/Individual',
        name: 'Individual',
        component: () => import( '../views/Individual.vue'),
        meta: {},
        children: [
            {
                path: '/SettingConfig',
                name: 'SettingConfig',
                component: () => import( '../views/SettingConfig.vue'),
                meta: {
                    title: '个人资料'
                }
            },
            {
                path: '/orderDetails',
                name: 'orderDetails',
                component: () => import( '../views/orderDetails.vue'),
                meta: {
                    title: '订单详情',
                    parent: '/order'
                }
            },
            {
                path: '/order',
                name: 'order',
                component: () => import( '../views/order.vue'),
                meta: {
                    title: '我的订单',
                }
            },
            {
                path: '/DeliveryAddress',
                name: 'DeliveryAddress',
                component: () => import( '../views/DeliveryAddress.vue'),
                meta: {
                    title: '收货地址'
                }
            },
            {
                path: '/task',
                name: 'task',
                component: () => import( '../views/task.vue'),
                meta: {
                    title: '我的任务'
                }
            },
            {
                path: '/profit',
                name: 'profit',
                component: () => import( '../views/profit.vue'),
                meta: {
                    title: '利润设置'
                }
            },
            {
                path: '/feedback',
                name: 'feedback',
                component: () => import( '../views/feedback.vue'),
                meta: {
                    title: '售后反馈'
                }
            },
            {
                path: '/feedbackDetails',
                name: 'feedbackDetails',
                component: () => import( '../views/feedbackDetails.vue'),
                meta: {
                    title: '售后反馈详情',
                    parent: '/feedback'
                }
            },
            {
                path: '/password',
                name: 'password',
                component: () => import( '../views/password.vue'),
                meta: {
                    title: '修改密码'
                }
            },
            {
                path: '/policiesList',
                name: 'policiesList',
                component: () => import( '../views/policiesList.vue'),
                meta: {
                    title: '优惠列表'
                }
            },
            {
                path: '/noticeList',
                name: 'noticeList',
                component: () => import( '../views/noticeList.vue'),
                meta: {
                    title: '公告列表'
                }
            },
        ],

    },



]



const router = new VueRouter({
    mode: 'hash',
    routes,
    // scrollBehavior: () => {
    //     history.pushState(null, null, document.URL)
    // }
})

export default router
