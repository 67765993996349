import Vue from 'vue'
import Vuex from 'vuex'
import user from "./modules/user";
import ws from "./modules/ws";
import createPersiste from 'vue-savedata'
import axios from "axios";
import VueViewer from 'v-viewer'

const persiste = createPersiste({
    // 加密存本地, 默认为false
    ciphertext: true,
    mode: 'SS',
    LS: {
        module: user,
        storePath: 'user'
    },
    SS: [
        {
            module: ws,
            storePath: 'ws'
        }
    ],

})
Vue.use(Vuex)
Vue.use(VueViewer)

export default new Vuex.Store({
    state: {
        text:'PDFGH SHOP 晋ICP备2021',
        keyword:'',
        baseUrl:'http://erp.d33221.com:6300',
        cmenu: '',
        turnOnOff:true,
        tab:0,
        shopList:[],
        GwcNum:'',
        search:1,
        pay:'',
        show_type:0,
        router_path:{}
    },
    mutations: {
        setPay(state,pay) {
            state.pay = pay
        },
        setshop(state,shopList) {
            state.shopList = shopList;
        },
        settab(state,tab) {
            state.tab = tab;
        },
        setSeach(state,search) {
            state.search = search
        },
        setKeyWord(state, keyword) {
            state.keyword = keyword;
        },
        setTurnOnOff(state, turnOnOff) {
            state.turnOnOff = turnOnOff;
        },
        setCmenu(state, menu) {
            state.cmenu = menu
        },
        setGwcNum(state,GwcNum) {
            state.GwcNum = GwcNum
        },
        setShowType(state,show_type) {
            state.show_type = show_type
        },
        setRouterPath(state,router_path) {
            console.log(33333333333)
            state.router_path = router_path
        }
    },
    actions: {
        GetGwc({commit}) {
            return new Promise((resolve, reject) => {
                axios.post('/api/kuerp/order/index/getCartNumber', {}).then(res => {
                    commit('setGwcNum', res.data.data.num)
                    resolve(res.data.data.num)
                })
            })

        }
    },
    modules: {user, ws},
    plugins: [persiste],
})
