import fa from "element-ui/src/locale/lang/fa";

export default {
    state    : {
        token      : '',
        loginStatus: false,
        userInfo   : '',
        account    : '',
        username   : '',
        avatar      :'',
        isClient   : false,
    },
    getters  : {},
    mutations: {
        setUserInfo(state, userinfo) {
            state.loginStatus = userinfo.status;
            state.userInfo    = userinfo.data;
            state.username    = userinfo.username;
            state.avatar      = userinfo.avatar;
            state.is_vip      = userinfo.is_vip;
        },

        setAccount(state, account) {
            state.account = account;
        },

        setToken(state, token) {
            state.token = token;
        },

        setClient(state, isClient) {
            state.isClient = isClient
        }
    },
    actions  : {}
}
